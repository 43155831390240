.missing-items-buttons {
  button {
    margin-right: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }
  @media screen and (max-width: 768px){
    button {
      &:last-child {
        margin-left: 0;
      }
    }
  }
}