.error {
  &-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-title {
    font-size: 7rem;
  }
  &-link {
    font-size: 2em;
  }
}
