.registration {
  &_header {
    max-width: 1000px;
    height: 40px;
    margin: 10px auto 0 auto;
    padding: 0 20px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }
  &_language-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    transition: none;
  }
  &_language-select.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    transition: none;
  }

  &_language-select {
    width: 60px;
    text-align: center;
    color: #2c2c2c;
    padding: 5px;
    transition: none;

    * {
      transition: none;
    }


    &:hover, &:focus {
      background-color: #f93822;
      color: #FFFFFF;
    }

    &:hover span.ant-select-selection-placeholder {
      transition: none;
      color: #FFFFFF;

    }

    .ant-select-selection-placeholder {
      color: #2c2c2c;
      transition: none;
    }
  }


  &_content.content {
    background-color: transparent;
    width: 100%;
    max-width: 600px;
    padding: 40px 20px;
    margin: 0 auto;
  }

  &_title, &_description {
    text-align: center;
  }
}

@media screen and (min-width: 560px){
  .registration {
    &_content.content {
      padding: 40px 0;
    }
  }
}
