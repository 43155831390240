.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    // margin-bottom: 50px;
    font-size: 5vmin;
  }
  &-input.ant-input {
    margin-bottom: 20px;
    max-width: 320px;
  }
}