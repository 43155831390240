.wiki-content {
  .markdown-viewer {
    h1, h2, h3, h4, h5 {
      margin: 25px 0 15px 0;
    }
    h1 {
      font-size: 24px !important;
    }
    h2 {
      font-size: 22px !important;
    }
    h3 {
      font-size: 20px !important;
    }
    h4 {
      font-size: 18px !important;
    }
    h5 {
      font-size: 16px !important;
    }
  }
}