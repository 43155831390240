.catalog-product-price {
  text-align: right;
  padding-top: 30px;
  padding-bottom: 20px;
  .catalog-product-price-value {
    font-size: 34px;
    font-weight: bold;
    line-height: 1em;
  }
  .catalog-product-price-hint {
    color: #7F7F7F;
    padding-left: 40px;
    font-size: 14px;
  }
}