.comment-list {
  padding-bottom: 10px !important;
  &-item {
    .ant-comment-inner {
      padding: 8px 0;
      .ant-comment-content-author {
        margin-bottom: 0;
      }
    }
  }
}