.announcement {
  width: 100%;
  &.short {
    padding: 20px 30px;
    background: #fafafa;
  }

  > div {
    &.announcement-title {
      h2 {
        margin: 0;
        padding: 0;
      }
    }
    &.announcement-date {
      font-style: italic;
      font-size: 12px;
      color: #7F7F7F;
    }
    &.announcement-text {
      margin-top: 10px;
    }
  }
}