.order-checkout-table-summary {
  .ant-table {
    background: none;
    tr {
      background: none;
      th {
        font-weight: bold;
        text-align: right;
        padding: 8px 4px 10px 4px;
        &::before {
          display: none;
        }
      }
      td {
        background: none;
        border: none;
        padding: 4px;
        &:first-child {
          color: rgba(0, 0, 0, 0.45);
        }
        &:not(:first-child) {
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }
}