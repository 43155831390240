.login {
  &-buttons-col {
    display: flex;
    justify-content: center;
  }
  &-wrapper {
    position: relative;
    background-image: url(./ws_bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
    }
  }
  &-block {
    width: 100%;
    max-width: 350px;
    height: auto;
    z-index: 20;
    img {
      display: block;
      width: 80%;
      height: 100%;
      margin: 0 auto 50px auto;
    }
  }
  &-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    //min-width: 300px;

    .login-form {
      width: 100%;
    }
    padding: 20px;

    .register-btn {
      display: inline-block;
      width: auto;
    }
  }
  &-forgot-password {
    display: block;
    margin-top: 2px;
    margin-bottom: 24px;
  }
}

