.catalog_popover {
  display: flex;
  width: auto;
  align-items: center;
  left: 48px;
  top: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ededed;
  button.popup-button.ws_button {
    height: 44px;
  }
}

.catalog_content_card button.catalog_button,
button.ant-btn.catalog_content_card_button_popup {
  color: #7f7f7f;
  height: 44px;
  width: 45px;

  &:hover {
    color: #FFFFFF;
  }
}


