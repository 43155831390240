.basket-table {

  .basket-action-column {

    white-space: nowrap;
    min-width: 80px;
  }

  td.basket-article-description-column {
    min-width: 160px !important;
  }

  .backorder-qty {
    white-space: nowrap;
    color: #f93822;
    .descr {
      font-size: 10px;
    }
  }
}
.disabled, .link.disabled {
  color: #bdbdbd;
}
.card-table-buttons {
  display: flex;
  flex-wrap: wrap;

  .ant-btn:not(.ant-btn:last-child) {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  //justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .card-table-buttons {
    justify-content: flex-end;
    .ant-btn:not(.ant-btn:last-child) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}