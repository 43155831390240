.page_header_container {

  .mobile-navigation {
    &-container {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background-color: #2c2c2c;
      z-index: 13;
      transition: all .3s ease;
      animation-name: openAction;
    }



    &-button {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 0;
      right: 0;

      svg {
        height: 50%;
        width: 50%;
      }
    }

    &-list {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      overflow-y: auto;
      padding: 10px 0;

    }
    &-item.language-select {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-size: 1.2rem;
      .ant-select, .ant-select-arrow, .ant-select-selection-item{
        color: #FFFFFF;
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selector {
        padding: 0;
      }
      .ant-select-dropdown {
        left: 0!important;
        top: 45px !important;
      }
      svg {
        margin-right: 25px;
      }
      .ant-select {
        font-size: 1em;
      }
      &:hover, &:focus-within {
        background-color: #4089b7;
      }
    }

    &-item {
      width: 300px;
      max-width: 300px;
      height: 45px;
      list-style: none;
      color: #FFFFFF;



      a, button.page_header_container-link {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 20px;
        background: #2c2c2c;
        color: #FFFFFF;
        text-decoration: none;
        box-sizing: border-box;
        text-align: center;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -webkit-appearance: none;
        font-size: 1.2rem;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 25px;
        }

        &:focus, &:hover {
          background-color: #4089b7;
        }

      }
      button.page_header_container-link {
        &:hover, &:focus {
          background-color: #f93822;
        }
      }
    }

    &-item.claims {
      a {
        &:focus, &:hover {

          background-color: #f7ba45;
          color: #2c2c2c;
          path {
            fill: #2c2c2c;
          }
        }
      }
    }

    &-icon {
      margin-right: 25px;
      width: 40px;
    }

  }
  .mobile-navigation.open {
    animation: openAction 0.3s ease;
    opacity: 1;
  }
  .mobile-navigation.close {
    animation: closeAction 0.5s ease-in;
    opacity: 0;
  }

}
