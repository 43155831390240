.filter-table-btn {
  margin-left: auto;
}

button.ant-btn.ant-btn-icon-only.table-columns-button {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
}