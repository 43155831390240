.catalog_content_card {
  > div {
    margin-bottom: 20px;
  }
  width: 100%;
  height: auto;
  padding: 20px 25px 20px 25px;
  border-bottom: 3px solid #f5f6f8;
  background-color: #fff;
  .content-card-header a {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    text-decoration: underline;
  }
}