.product-info {
  margin-top: 12px;
  .product-info-title {
    margin-top: 6px;
    margin-bottom: 6px;
    color: #3D3D3D;
  }
  .product-info-list {
    table {
      tr {
        td {
          color: #7F7F7F;
          font-size: 14px;
          line-height: 1.4em;
        }
        &.highlighted {
          td {
            background-color: #fa5e3b !important;
            color: #ffffff;
          }
        }
      }

      tr:nth-child(even) {
        td {
          background-color: #FAFAFA;
        }
      }
    }
  }
}