* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: sans-serif;
}
html,
body,
#root {
    min-height: 100%;
}
#root {
    display: flex;
    flex-direction: column;
}
main {
    flex: 1 0 auto !important;
}
footer {
    flex-shrink: 0;
}

body {
    flex-direction: column;
    background-color: #f5f6f8 !important;
}

footer.page_footer {
    span {
        text-align: center;
        color: #7f7f7f;
        padding: 12px 0;
    }
}

.page_header,
.page_content,
.page_footer,
.catalog_search_block,
.catalog_content_block,
.article_container {
    display: flex;
    flex-direction: column;
    max-width: 1050px;
    width: 100%;
    min-width: 300px;
    margin: 0 auto;
}

.page_content_container,
.page_footer_container {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}

button.ws_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    color: #fff;
    background: transparent;
    border: none;
    transition: none;
    border-radius: 0;
    box-shadow: none;

    &:hover,
    &:focus {
        background-color: #f93822;
        color: #ffffff;
    }
}

.ant-typography.main-title {
    margin: 0;
    width: fit-content;
    padding-bottom: 10px;
    border-bottom: 2px solid #7f7f7f;
}

h1.ant-typography,
h2.ant-typography {
    font-weight: normal !important;
}

.alert {
    border-left: 10px solid #f7ba45;
}

.content {
    height: auto;
    margin-top: 5px;
    padding: 30px 20px;
    background-color: #fff;
    width: 100%;
}

.content-inner {
    width: 100%;
}

div.data-descriptions-with-edit-wrapper {
    position: relative;

    button.data-description-btn {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.card-col {
    position: relative;

    .buttons-wrapper {
        position: absolute;
        top: 10px;
        right: 20px;
        max-width: 0;
        opacity: 0;
        transition: all 0.2s ease;
        z-index: 0;
        transform: scale(0);
    }

    &:hover {
        .buttons-wrapper {
            max-width: 1000px;
            transform: scale(1);
            opacity: 1;
            z-index: 2;
        }
    }
}

button.ant-btn.btn {
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: gray;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    position: relative;

    &:hover,
    &:focus {
        background-color: #282c34;
        color: #ffffff;
    }

    &-remove {
        &:hover,
        &:focus {
            border-color: #f93822;
            background-color: #f93822;
            color: #fff;
        }
    }
}
div.ant-table {
    // font-size: 13px;

    thead.ant-table-thead {
        th {
            background-color: transparent;
        }

        .ant-table-selection-column {
            padding-left: 13px;
        }
        th.ant-table-selection-column.ant-table-cell {
            //display: flex;
            align-items: center;
            justify-content: center;
            div.ant-table-selection {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    tbody.ant-table-tbody {
        .ant-table-selection-column {
            padding-left: 13px;
        }
        tr.ant-table-row-selected {
            > td {
                background: #dff1d8;
            }
            &:hover {
                background: #dff1d8;
            }
        }
    }

    tr.ant-table-row {
        button.ant-btn.btn {
            border-color: transparent;
            box-shadow: none;

            &:hover,
            &:focus {
                border-color: #282c34;
            }
        }
        button.ant-btn.btn.btn-remove {
            &:hover,
            &:focus {
                border-color: #f93822;
            }
        }
        &:nth-child(2n) {
            background: #fafafa;
        }
    }
}

div.ant-space {
    width: 100%;
}

div.ant-collapse-header {
    .ant-collapse-extra {
        line-height: 2.2;
    }
}

.data-content-wrapper {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
}

.data-content-wrapper-descriptions.ant-descriptions.ant-descriptions-small {
    div.ant-descriptions-view {
        table {
            tbody {
                th,
                td {
                    padding: 0;
                }

                tr.ant-descriptions-row:nth-child(2n):not(tr.ant-descriptions-row:last-child) {
                    > td {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}

body {
    button.ant-btn.ant-btn-icon-only:not(.free_button, .ws_button, .ant-btn.filter-btn) {
        width: 25px;
        height: 25px;
    }

    button.ant-btn.ant-btn-icon-only.form-list-btn,
    span.form-list-btn,
    span.form-list-btn > button {
        width: 100% !important;
        height: 32px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        .ant-checkbox {
            top: 0;
            * {
                width: 16px;
                height: 16px;
            }
        }
    }

    .ant-checkbox-inner::after {
        width: 6px;
        height: 9px;
    }

    .ant-checkbox-wrapper.large {
        * {
            width: 25px;
            height: 25px;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            transform: rotate(45deg) scale(1) translate(-4.5px, -8px);
        }
        .ant-checkbox-inner::after {
            width: 8px;
            height: 14px;
        }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #f93822;
    }

    .ant-checkbox-inner {
        border-radius: 50%;
    }
    .ant-checkbox-checked {
        &:after {
            border: none;
        }

        .ant-checkbox-inner {
            background-color: #f93822;
            border-color: #f93822;
        }
    }

    .ant-checkbox-disabled {
        .ant-checkbox-inner {
            background-color: #f5f5f5;
            border-color: #d9d9d9 !important;
        }
    }
}

thead.ant-table-thead {
    th {
        .ant-checkbox-checked {
            .ant-checkbox-inner,
            .ant-checkbox-inner::after {
                transition: none;
            }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner {
            overflow: hidden;
            &::after {
                z-index: 1;
                width: 25px;
                height: 25px;
                background-color: #f93822;
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(1);
                transition: none;
            }
        }
        .ant-checkbox-indeterminate {
            position: relative;
            z-index: 2;
            &:after {
                z-index: 2;
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                width: 7px;
                height: 2px;
                background-color: #fff;
                transform: translate(-46%, -51%);
            }
        }
    }
}

.ant-card.card {
    background-color: #f1f1f1;
    border-radius: 10px;
    height: 100%;

    .ant-card-head {
        border: none;
        padding: 0 20px;
        span.ant-typography-ellipsis {
            max-width: 89%;
        }

        .ant-card-extra,
        .ant-card-head-title {
            padding: 10px 0 5px 0;
        }

        .ant-card-head-title {
            font-weight: 400;
            font-size: 1.3rem;
        }
    }

    .ant-card-body {
        padding: 0 20px 15px 20px;
    }
}
.article-link {
    white-space: nowrap;
}
.article-stocks {
    span.ant-tag {
        display: inline-flex;
        padding: 4px 8px;
        font-size: 14px;
        margin-bottom: 5px;
        strong {
            font-size: 18px;
            margin-right: 4px;
        }
        span {
            display: block;
            margin-left: 2px;
        }
    }
    span.ant-tag.no-stock {
        opacity: 0.5;
    }
}

.ant-card.card,
.card-col {
    button.ant-btn[disabled] {
        border-radius: 50%;
        svg {
            margin-top: -1px;
        }
    }

    button.card-btn {
        border: none;
        border-radius: 50%;
    }
}

.ant-card.card.ant-card-hoverable {
    &:focus-within {
        border-color: transparent;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12),
            0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
}

.ant-card.card.card-transparent {
    background-color: transparent;
}
.ant-notification-hook-holder,
.ant-notification-notice {
    z-index: 100000;
}

.link {
    text-decoration: underline;
    color: #2c2c2c;
    transition: all 0.2s ease;

    &:focus,
    &:hover {
        color: #f93822;
        text-decoration: underline;
        font-weight: 500;
    }
}

.upload-wrapper {
    width: 100%;
    span {
        width: 100%;
        height: 100%;
        .ant-upload.ant-upload-drag {
            padding: 30px;
        }
    }
}
.row-no-margin.ant-row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

@media screen and (min-width: 560px) {
    .page_header,
    .page_content,
    .page_footer,
    .catalog_content_block,
    .article_container {
        padding: 0 20px;
    }
}

button.sort-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px !important;
}
span.sort-btn {
    width: 100%;
    button[disabled] {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px !important;
    }
}

.ant-message {
    .no-shadow-message > * {
        box-shadow: none !important;
    }
}

.no-wrap {
    white-space: nowrap;
}

.dashed-link {
    border-bottom: 1px dashed #1890ff!important;
}

*[data-show="false"] {
    display: none !important;
}