.catalog-product-in-cart-qty {
  .catalog-product-in-cart-buttons {
    margin-bottom: 5px;
    display: flex;
    > div {
      text-align: center;
      padding: 2px 0px;
      font-size: 26px;
      font-weight: bold;
      flex:1;
    }
    .catalog-product-in-cart-button {
      background-color: #F1F1F1;
      color: #000000;
      cursor: pointer;
    }
    .catalog-product-in-cart-value {
      color: #000000;
    }
  }
  .catalog-product-update-basket {
    background-color: #F1F1F1;
    color: #7F7F7F;
    cursor: pointer;
    padding: 10px 20px;
    font-weight: bold;
    text-align: center;
  }
  .catalog-product-already-in-basket {
    margin-top: 5px;
    background-color: #F93822;
    color: #FFFFFF;
    text-align: center;
  }
}