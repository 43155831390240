.product-stock-info {
  margin-bottom: 12px;
  .product-stock-info-title {
    margin-bottom: 6px;
  }
  .product-stock-info-stocks {
    span.ant-tag {
      display: inline-flex;
      padding: 4px 8px;
      font-size: 14px;
      margin-bottom: 5px;
      strong {
        font-size: 18px;
        margin-right: 4px;
      }
      span {
        display: block;
        margin-left: 2px;
      }
    }
    span.ant-tag.no-stock {
      opacity: 0.5;
    }
  }
}