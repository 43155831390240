.catalog_view_panel {
  background-color: #FFF;
  margin-top: 3px;
  margin-bottom: 3px  ;

  button.catalog_view_panel_button {
    color: #2c2c2c;
    height: 40px;

    border-left: 1px solid #f5f6f8;

    &:hover, &:focus {
      background-color: #f5f6f8;
      color: #2c2c2c;
    }

  }

  &_count {
    display: flex;
    align-items: center;
    width: auto;
    height: 40px;

    color: #49494a;
    line-height: 4rem;
    margin-left: 20px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}
