.article {

  h1.article_title {
    margin-bottom: 50px;
    span {
      color: #7f7f7f
    }
  }
  &_title-loader {
    margin-bottom: 50px;
  }
  &_stocks {
    &-wrapper {
      display: flex;

      svg {
        margin-right: 10px;
        path {
          fill: #7f7f7f;
        }
      }
    }

  }
  &_description {
    .ant-descriptions-header {
      margin-bottom: 10px;
    }
    .ant-descriptions-row:nth-child(2n + 1) {
      background: #fafafa;
    }
    .ant-descriptions-row > td,
    .ant-descriptions-row > th{
      padding: 10px;
      width: 50%;
    }
  }
  &_description.ant-descriptions-bordered {
    .ant-descriptions-item-label {
      background-color: transparent;
    }
  }

  &_img-block {
    margin-bottom: 30px
  }
  &_info_img-block {
    margin-bottom: 20px;
  }
  &_info_img-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    img {
      display: flex;
      width: 100%;
      max-width: 200px;
      height: auto;
      margin: 0 auto;
      cursor: pointer;
    }
    .ant-skeleton.ant-skeleton-element {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      >.ant-skeleton-image {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_img_thumbs {
    .ant-skeleton.ant-skeleton-element {
      width: 90px;
      height: 90px;
      >.ant-skeleton-image {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_buttons-wrapper {
    .cart-button-wrapper {
      button.article-wish-list-btn {
        margin-right: 10px;
      }
    }
    .price-wrapper {
      margin-right: 17px;
      margin-bottom: -4px;
      font-size: 21px;
      font-weight: bold;
      img {
        margin-right: 10px;
      }
      .price-description {
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  &_cart-btn.ant-btn {
    font-size: 0.9rem;
    height: 40px;
    border-radius: 0;
    border-color: #f93822;
    background-color: unset;
    color: #f93822;
    &:focus, &:hover {
      border-color: #f93822;
      color: #f93822;
      box-shadow: none;
    }

    &.dark {
      color: #2c2c2c;
      border-color: #2c2c2c;
      &:focus, &:hover {
        border-color: #2c2c2c;
      }
    }
    &.in-cart, &:hover {
      background-color: #f93822;
      color: #fafafa;
      &:focus, &:hover {
        background-color: #f93822;
        color: #fafafa;
      }
      &.dark {
        background-color: #2c2c2c;
        &:focus, &:hover {
          background-color: #2c2c2c;
        }
      }
    }
  }

  &_collapse.ant-collapse {
    width: 100%;
    background-color: transparent;
    thead {
      th {
        font-weight: bold;
      }
    }
    >.ant-collapse-item {
      >.ant-collapse-header {
        font-weight: bold;
        background-color: #E7E7E7;
        color: #545F64;
      }
    }
    .ant-collapse.article_collapse-inner {
      background-color: transparent;

      .ant-collapse.article_collapse-inner {
        >.ant-collapse-item {
          border: none;
          >.ant-collapse-header {
            border-bottom: 2px solid #D9D9D9;
          }
        }
      }
    }
    .ant-collapse.article_collapse-model {
      >.ant-collapse-item {
        >.ant-collapse-header {
          font-weight: bold;
          color: #7F7F7F;
        }
      }
    }
  }

  &_collapse-loader {
    width: 100%;
    .ant-skeleton-element {
      width: 100%;
      > .ant-skeleton-button {
        width: 100%;
      }
    }
  }
}
.popup-btns-wrapper {
  position: relative;
  float: left;
}

.article_stocks-wrapper {
  .article_stocks_icon-wrapper {
    width: 40px;
  }
  .article-stocks {
    button {
      padding-top: 3px;
      height: 29px;
    }
  }
}

@media screen and (min-width: 960px) {
  .article {
    &_stocks-wrapper {
      .article-stocks {
        span.ant-tag {
          margin-bottom: 5px;
        }
      }
    }
  }
}
