.catalog_search_block_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

button.catalog_button.ws_button,
button.catalog_button {
  color: #7f7f7f;
  height: 45px;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    background-color: #f93822;
    color: #ffffff;
  }
}

button.catalog_button.cart-btn.in-cart {
  font-size: 1.1rem;
  height: 45px;
  border-radius: 0;
  border-color: #f93822;
  background-color: #f93822;
  padding: 4px 15px;
  display: block;
  width: auto;
  // color: #fafafa;
  // &:focus, &:hover {
  //   border-color: #f93822;
  //   background-color: #f93822;
  //   color: #fafafa;
  //   box-shadow: none;
  // }
}
button.ant-btn.catalog_button.catalog_button_active {
  color: #ffffff;
  background-color: #f93822;
}

@media screen and (min-width: 768px) {
  .catalog_search_block_container {
    position: sticky;
    top: 45px;
    z-index: 10;
  }
}

.catalog_search_block {
  padding: 10px 20px 20px 20px;

  .ant-skeleton-element {
    width: 100%;
  }
  .search_block {
    padding: 10px 0;
    position: relative;
    width: 100%;
    height: auto;

    &_input {
      width: 100%;
      .ant-select-selector {
        .ant-select-selection-search {
          right: unset;
          left: 0;
        }
      }
    }


    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      left: 0;
      background-color: #e0e0e0;
    }
  }

  .selects_block {
    margin-top: 10px;

    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      left: 0;
      right: unset;
    }
    .selects-col-icon {
      display: flex;
      align-items: center;
    }

    &_select {
      width: 100%;
      .ant-select-selection-placeholder {
        color: #2c2c2c;
      }

    }

    &_select.ant-select-disabled {
      .ant-select-selection-placeholder {
        color: #BFBFBF;
      }
    }

    &_divider {
      background: #e0e0e0;
      height: 32px;

      &_vertical {
        display: none;
      }

      &_horizontal {
        height: 1px;
        margin: 0;
      }
    }

    button.ant-btn.selects_block_button {
      background-color: #f93822;
      height: 40px;
      width: 40px;
      display: block;
      margin-left: auto;

      &:hover {
        color: #FFFFFF;

      }
    }
  }

  .selects_block.selects_block_loader {
    .ant-col:not(.ant-col:nth-child(3)), .ant-col:not(.ant-col:nth-child(5)) {
      padding-right: 5px;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px 0 0;
  }



}


@media screen and (min-width: 768px) {
  .catalog_search_block {

    .selects_block {

      &_divider {
        &_horizontal {
          display: none;
        }

        &_vertical {
          display: block;
        }
      }
    }
    .selects_block.selects_block_loader {
      .ant-col {
        padding-right: 0 !important;
      }
    }
  }
}