.ant-collapse-borderless {
  background-color: transparent !important;
  .ant-collapse-item.cart-collapse-panel {
    border: none;
  }
   > .ant-collapse-item.cart-collapse-panel {
    > div.ant-collapse-header {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .ant-collapse-content {
      background: transparent;
    }
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}
