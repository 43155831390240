.documents-link,
button.documents-link {
    color: #545f64;
    background-color: #f1f1f1;
    padding: 10px 15px;
    margin-bottom: 5px !important;
    transition: none;
    border: none;
    &:focus,
    &:hover {
        color: #ffffff;
        background-color: #f93822;
    }
    &.danger {
        background-color: #ec3e3e;
        color: #ffffff;
    }
}
button.documents-link {
    display: block;
    padding: 7px 15px;
    height: auto;
    border-radius: 0;
    box-shadow: none;
}
