.claim-btn {
  height: 120px !important;
  font-size: 16px !important;
  color: #fff !important;
  border: none !important;
  opacity: 0.8;
  &.reclamation {
    background-color: #804343 !important;
  }
  &.return {
    background-color: #807c43 !important;
  }
  &:hover {
    opacity: 1;
  }
  >span {
    display: block !important;
  }
}