.page_header_container {
    background-color: #2c2c2c;
    -webkit-box-shadow: 0 0 6px 0 rgb(0 0 0 / 22%);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 22%);
    z-index: 100;
    height: 45px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .ant-row {
        height: 45px;
    }

    .page_header_hamburger {
        height: 100%;
    }

    a.page_header_container-link {
        transition: none;
        height: 45px;

        &:hover,
        &:focus {
            background-color: #f93822;
        }
        &-active {
            background-color: #f93822;
        }
    }
    a.page_header_container-link.hover-white {
        &:hover,
        &:focus {
            background-color: #fff !important;
        }
        &-active {
            background-color: #fff !important;
        }
    }

    a.page_header_container-link,
    button.page_header_container-link,
    .page_header_container-main-links-container a.page_header_container-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 10px;
        width: 45px;
        text-decoration: none;
        color: #fff;
    }

    .page_header_container-main-links-container {
        flex: 1;
        width: auto;
        overflow: hidden;
        span {
            display: none;
        }

        .page_header_container_separator {
            display: none;
            width: 15px;
            height: 25px;
            border-left: 1px solid #fff;
            margin: 10px 0 10px 15px;
        }

        a.page_header-claim-link {
            &:hover,
            &:focus {
                background-color: #f7ba45;
                color: #313439;
                path {
                    fill: #313439;
                }
            }
        }

        a.page_header_container-link_hide {
            display: none;
        }
    }

    button.log-out-link {
        display: none;
    }

    .nav-img-container img {
        width: 25px;
    }

    div.page_header_language-wrapper {
        display: none;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 0 13px;
        cursor: pointer;
        text-transform: uppercase;
        span {
            display: none;
        }
        svg,
        .ant-select,
        .ant-select-selection-item {
            color: #ffffff;
        }

        &:hover,
        &:focus-within {
            background-color: #f93822;
            outline: none;
        }
        .ant-select-selector {
            padding: 0;
        }

        .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
            left: 0 !important;
            top: 45px !important;
            width: 100% !important;
        }
    }
    .page_header_language-select:focus .page_header_language-wrapper {
        background: #000;
    }
}

.nav-item-popover {
    .ant-popover-arrow-content::before, .ant-popover-inner {
        background-color: #2c2c2c !important;
    }
    .ant-popover-inner-content {
        color: #ffffff;
    }
}

@media screen and (min-width: 960px) {
    .page_header_container {
        div.page_header_language-wrapper {
            span {
                display: block;
            }
            padding: 0 0 0 11px;
            .ant-select-selector {
                padding: 0 11px;
            }
        }
        .page_header_container-main-links-container {
            span {
                margin-left: 10px;
                display: block;
            }

            a.page_header_container-link {
                width: auto;
            }
        }
    }
}

@media screen and (min-width: 560px) {
    .page_header_container {
        div.page_header_language-wrapper {
            display: flex;
        }
        button.page_header_hamburger {
            display: none;
        }

        button.log-out-link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .page_header_container-main-links-container {
            transform: translateX(10px);
            .page_header_container_separator {
                display: flex;
            }

            a.page_header_container-link_hide {
                display: flex;
            }
        }
    }
}
